<template>
  <ion-page>
    <!-- Header -->
    <Header page="place" :username="place.name" />
    <!-- Page Content -->
    <ion-content color="dark" :fullscreen="true">
    
      <ion-item color="dark">
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-avatar v-if="place.profile != null" slot="start">
          <img v-if="place.profile.profile_photo_path != null" :src="place.profile.profile_photo_path">
          <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
        </ion-avatar>
        <ion-icon v-else :icon="business" />
        <ion-label style="margin-left:15px;">
          <h2>{{place.name}}</h2>
          <p>{{place.formatted_address}}</p>            
        </ion-label>
      </ion-item>   
      <FeedWrapper feed-type="place" :profile-id="0" :place-id="place.id" />
    </ion-content>
  </ion-page>

</template>

<script>
import { IonPage, loadingController, IonContent, IonItem, IonAvatar, IonIcon, IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed } from 'vue';
import Header from '../components/Header.vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { business } from 'ionicons/icons';
import FeedWrapper from '../components/FeedWrapper.vue';

export default defineComponent({
  name: 'Place',
  components: {
    IonPage, IonContent, IonItem, IonAvatar, IonIcon, IonLabel, Header, FeedWrapper
  },
  props: ['slug'],
  setup(props) {
    const store = useStore();
    const authToken = computed(() => store.state.authToken);
    const timeout = { default: 6000 }
    const place = ref([]);

    onMounted(() => {
      getPlace()
    })

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    function getPlace() {
      presentLoading()

      apiClient.get('/api/get/place/' + props.slug,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          place.value = response.data
        }).catch(error => {
          console.log(error)
        });        
    }

    return {
      authToken, presentLoading, place, business
    }
  }
});
</script>

<style scoped>

.theme-light {
  background:#f4f5f8!important;
}
</style>